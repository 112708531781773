import { Listbox } from "@headlessui/react";
import { Button } from "@windmill/react-ui";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";
import Hints from "../question/Question";
import BackButton from "./BackButton";
import OnBoardingContainer from "./onBoardingContainer";
import webCurrencyCodes from "../../utils/webCurrencyCodes";

export default function AddStorename({
  onSubmit,
  setStoreData,
  isLoading,
  setStep,
  storeData,
}) {

  return (
    <div>
      <div
        className="min-h-screen bg-green-50 flex justify-center items-center px-3"
        // style={{
        //   backgroundImage: `linear-gradient(hsla(231, 47%, 40%, 0.625),hsla(231, 47%, 35%, 0.625)),url(${bgImage})`,
        // }}
      >
        <div className="container mx-auto">
          <div className="max-w-lg mx-auto ">
            <div className="w-full">
              <OnBoardingContainer>
                <div className=" p-6 lg:p-8 lg:px-10  ">
                  <img src={Storebounty_logo} alt="" className="w-40 h-auto" />
                  <h1 className="font-medium text-lg mt-6">Business details</h1>
                  <p className="text-xs mt-1 text-gray-500  w-8/12">
                    Enter your business name and select your default currency.
                  </p>
                  <fieldset>
                    <div className="flex flex-col gap-1 text-left  mt-6">
                      <label className="text-sm font-medium leading-tight">
                        Enter business name
                      </label>
                      <Hints
                        hint={
                          "Input name you want your store to be identified with."
                        }
                      />
                      <div className="col-span-8 sm:col-span-4">
                        <input
                          className="w-full focus:border-green-50 text-sm border focus:shadow-md px-3 h-10 focus:outline-none rounded"
                          onChange={(e) =>
                            setStoreData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }))
                          }
                          label="Business Name name"
                          name="name"
                          type="text"
                          placeholder="Enter a business name"
                          required={true}
                        />
                        {/* <Error errorName={errors.name} /> */}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 text-left mt-4">
                      <label className="text-sm font-medium leading-tight">
                        Select store default currency
                      </label>
                      <Hints
                        hint={
                          "Select store currency (Cannot be changed later)."
                        }
                      />
                      <div className="col-span-8 sm:col-span-4">
                        <div className="relative">
                          <Listbox
                            value={storeData.currency_code}
                            onChange={(e) =>
                              setStoreData((prev) => ({
                                ...prev,
                                currency_code: e,
                              }))
                            }
                          >
                            <Listbox.Button className="border border-gray-300 px-3 h-10 rounded flex justify-between gap-2 w-full items-center text-xs bg-white focus:outline-none pr-2 border-r">
                              Select store currency
                            </Listbox.Button>
                            <Listbox.Options className="absolute z-50 w-full h- h-auto max-h-64 pt-3  mt-1 overflow-auto text-base bg-white  rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {Object.keys(webCurrencyCodes).map((currency) => (
                                <Listbox.Option
                                  key={webCurrencyCodes[currency]}
                                  value={webCurrencyCodes[currency]}
                                  className={({ active, selected }) =>
                                    `
            ${selected && "bg-green-500 text-white"} 
            cursor-default select-none relative py-1 pl-4 pr-4 gap-2 hover:bg-green-200 
            ${active ? "bg-green-900 text-white" : ""}`
                                  }
                                >
                                  {`${
                                    webCurrencyCodes[currency]
                                  } (${getSymbolFromCurrency(
                                    webCurrencyCodes[currency]
                                  )})`}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Listbox>
                          {/* <Error errorName={errors.currency_code} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 text-left mt-4">
                      <label className="text-sm font-medium leading-tight">
                        Your free storefront
                      </label>
                      <div className="flex items-center mt-">
                        <input
                          className="w-full focus:border-green-50 text-sm border focus:shadow-md px-3 h-10 focus:outline-none rounded"
                          onChange={(e) =>
                            setStoreData((prev) => ({
                              ...prev,
                              sub_domain: e.target.value,
                            }))
                          }
                          type="text"
                          name="sub_domain"
                          id="sub_domain"
                          placeholder=""
                          value={storeData.sub_domain}
                          required
                        />
                        <p
                          layout="link"
                          className=" p-0 text-green-500 font-semibold"
                        >
                          .storebounty.ng
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 w-full mx-auto flex justify-between">
                      <BackButton setStep={setStep} />
                      <Button
                        disabled={isLoading}
                        onClick={onSubmit}
                        className="lg:px-12 px-6 my-6"
                        style={{ borderRadius: "4px" }}
                      >
                        Create Store
                      </Button>
                    </div>
                  </fieldset>
                </div>
              </OnBoardingContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
