import { Avatar, Button } from "@windmill/react-ui";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Cookies from "js-cookie";
import { AiOutlinePlus } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import troubleshootingImg from "../assets/img/troubleshooting.png";
import GiftSvg from "../assets/svgs/Gift.svg";
import LogoutBtn from "../components/common/LogoutBtn";
import Logo from "../components/logo/Logo";
import CreateStore from "../components/modal/CreateStore";
import Loading from "../components/preloader/Loading";
import { AdminContext } from "../context/AdminContext";
import useStoreInvite from "../hooks/formSubmits/useStoreInvite";
import useAsync from "../hooks/useAsync";
import useToggleDrawer from "../hooks/useToggleDrawer";
import StorageServices from "../services/StorageServices";
import StoreServices from "../services/StoreServices";

export default function SelectStore() {
  const { handleCreateOpen } = useToggleDrawer();
  const history = useHistory();
  const { data: inviteData } = useAsync(StoreServices.getInvite);

  const { dispatch, state } = useContext(AdminContext);
  const { adminInfo } = state;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const invites = inviteData?.data;

  useEffect(() => {
    const fetchStore = async () => {
      setLoading(true);
      try {
        const stores = await StoreServices.start();
        setData(stores);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchStore();
  }, []);
  const { onSubmit, inviting, declining } = useStoreInvite(data);

  const handleStoreSelect = (activeStore) => {
    //Beacause of accept invite user info may not be set for existin stores after login
    const userJson = StorageServices.get("adminInfo");
    const user = userJson ? JSON.parse(userJson) : null;
    if (!adminInfo) {
      dispatch({ type: "USER_LOGIN", payload: user });
    }

    StorageServices.set("SB-Active-Store-Id", activeStore.id);
    StorageServices.set(
      "SB-Active-Store-Currency-Code",
      activeStore.currency_code
    );
    StorageServices.set(
      "SB-Active-Store-Stats",
      JSON.stringify(activeStore.stats)
    );
    StorageServices.set("SB-Active-Store", JSON.stringify(activeStore));
    StorageServices.set("SB-Stores", JSON.stringify(data.stores));
    Cookies.remove("SB-business-token");
    Cookies.set("SB-business-token", "Bearer " + activeStore.business_token);
    StorageServices.remove("SB-cart");
    dispatch({ type: "ACTIVE_STORE", payload: activeStore });

    if (!activeStore.default_theme) {
      history.replace("/dashboard/theme");
      return;
    }
    if (activeStore?.stats?.product_count < 1) {
      history.replace("/products");
      return;
    }

    const userRole = user?.roles?.[activeStore.id]?.[0];

    if (userRole === "admin" || userRole === "super_admin") {
      history.replace("/");
    } else {
      history.replace("/orders/sell");
    }
  };

  useEffect(() => {
    if (!loading && data?.data?.stores.length === 0 && invites?.length === 0) {
      history.push("/onboarding");
    }
  }, [data, history, loading, invites]);

  return (
    <>
      <CreateStore data={data} />
      <div className="flex flex-col  dark:bg-gray-700 min-h-screen dark:text-white">
        {/* className="self-start justify-self-start  logo lg:ml-24 ml-4 mt-8 mb-6" */}
        <div className="flex justify-end lg:justify-between items-center  mx-auto max-w-7x px-12 mt-12 w-full">
          <Logo margin="mt-0 " />
          <LogoutBtn />
        </div>
        <div className=" mx-auto max-w-5xl w-full border-b border-gray-200 dark:border-gray-800 pb-4 p-2 md:px-0 px-6 mt-8">
          {/* <h1 className="text-2xl">My Stores</h1> */}
          <div className="max-w-6xl  md:px-0 px-6 mx-auto w-full">
            <h2 className="font-medium mt-2 ">My Stores</h2>
            <p className="text-sm mt-2">Select a Store you want to go to.</p>
          </div>
        </div>
        <div className=" mx-auto max-w-5xl bg-white rounded-lg p-2 w-full md:px-0 px-6 dark:bg-gray-700 dark:text-white">
          {invites?.length > 0 && (
            <div className="mb-8">
              <div className="font-serif font-medium pt-2 mb-3 pl-4 ">
                <span className="flex items-center text-sm">
                  <span>Pending Invites</span>
                </span>
              </div>
              {invites?.map((i) => (
                <div
                  key={i.invite_id}
                  className="font-serif font-medium pt-2 mb-3 pl-4 border border-dashed p-4 rounded-lg"
                >
                  <div className="flex gap-4">
                    {i.store_logo?.match(/^https?:\/\/.+\/.+$/) ? (
                      <Avatar
                        className="align-middle mt-2"
                        src={i.store_logo}
                        aria-hidden="true"
                        size="small"
                      />
                    ) : (
                      <img src={GiftSvg} alt="" className="w-6" />
                    )}
                    <div className="flex flex-col flex-1">
                      <span className="">{i.business_name}</span>
                      <span className="text-xs text-gray-500">{i.message}</span>
                    </div>
                    <div className="flex jus items-center gap-3 px-4">
                      <button
                        onClick={() =>
                          onSubmit({
                            invite_id: i.invite_id,
                            accepted: false,
                            business_code: i.business_code,
                          })
                        }
                        disabled={inviting || declining}
                        className="flex gap-2 border border-red-500 text-xs text-red-500 py-2 px-4 rounded-lg"
                      >
                        Decline
                        {declining && <ClipLoader size={20} color="red" />}
                      </button>
                      <button
                        onClick={() =>
                          onSubmit({
                            invite_id: i.invite_id,
                            accepted: true,
                            business_code: i.business_code,
                          })
                        }
                        disabled={inviting || declining}
                        className="flex gap-2 border border-green-500 text-xs text-green-500 py-2 rounded-lg px-1 whitespace-no-wrap"
                      >
                        Accept invite
                        {inviting && <ClipLoader size={20} color="#0230B1" />}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Loading loading={loading} />
          <div className="mt-6">
            {data?.data?.stores.length > 0 ? (
              <>
                <div className="grid justify-center items-center grid-cols-1  md:grid-cols-2 lg:grid-cols-3 gap-8 ">
                  {data?.data?.stores.map((store) => (
                    <div
                      key={store.id}
                      className=" rounded-md bg-gray-50 mx-auto w-full dark:bg-gray-800 border border-gray-200 dark:border-gray-700"
                    >
                      <div className="flex flex-col justify-center items-center mt-6">
                        <img
                          src={
                            store.base_url && store.logo_key
                              ? store.base_url + store.logo_key
                              : GiftSvg
                          }
                          alt=""
                          className="w-12 object-contain max-h-12"
                        />
                        <p className="whitespace-no-wrap py-5">{store.name}</p>
                      </div>

                      <div className="flex items-center pb-4 space-y-8">
                        <Button
                          onClick={() => handleStoreSelect(store)}
                          style={{
                            borderRadius: 4,
                          }}
                          className="mx-auto text-xs  text-center bg-green-500 text-white h-8 px-3 rounded-md "
                        >
                          Go to Store
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="col-span-4">
                {!loading && (
                  <>
                    <img
                      className="my-4 mx-auto"
                      src={troubleshootingImg}
                      alt="no-result"
                      width="400"
                    />
                    <div className="flex justify-center">
                      <Button
                        onClick={() => {
                          window.location.reload();
                        }}
                        layout="link"
                        className="text-green-500 dark:text-white"
                      >
                        <BiRefresh className="ml-2" />
                        Refresh
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {!loading && (
            <div className="flex justify-center items-center mt-8">
              <Button
                onClick={handleCreateOpen}
                className="flex items-center gap-2 text-green-500 dark:text-white"
                layout="link"
              >
                <AiOutlinePlus />
                <p>Create New Store</p>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
