import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import useDarkMode from "../../hooks/useDarkMode";

const Loading = ({ loading, className, message = "Please wait...", height = 25 }) => {
  const darkMode = useDarkMode();
  let loaderColor = "#091d8f";
  if (darkMode === 'dark') {
    loaderColor = "#ffffff";
  }
  return (
    <div className={`text-lg text-center ${loading ? className : ''}`}>
      {loading && (
        <>
          <ScaleLoader
            color={loaderColor}
            loading={loading}
            height={height}
            width={3}
            radius={3}
            margin={4}
          />
          <p className="text-sm text-green-500 dark:text-white">{message}</p>
        </>
      )}
    </div>
  );
};

export default Loading;
