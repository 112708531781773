import React, { useContext, useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import useAsync from "../../hooks/useAsync";
import useSelectStore from "../../hooks/useSelectStore";
import AdminServices from "../../services/AdminServices";
import StoreServices from "../../services/StoreServices";
import { notifyError } from "../../utils/toast";
import AboutStore from "./AboutStore";
import AddstoreName from "./AddStorename";
import ReadMoreScreen from "./ReadMoreScreen";
import StoreMode from "./StoreMode";
import WelcomeScreen from "./WelcomeScreen";
import WhatToSell from "./WhatToSell";

export default function Index() {
  const location = useLocation();
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const { handleStoreSelect } = useSelectStore();

  // using start here because you cant call product_0 when you dont have a store
  const { data, loading } = useAsync(StoreServices.start);
  const [isLoading, setIsLoading] = useState(false);
  const [storeData, setStoreData] = useState({
    name: "",
    description: "",
    sub_domain: "",
    currency_code: "",
    reg_channel: "web",
    product_type_id: "",
    pos_support: true,
    web_support: true,
    test_product: true,
    mode: "test",
    store_module: "general_store",
  });

  const [step, setStep] = useState(1);

  const onSubmit = async () => {
    if (!storeData.name) {
      notifyError("Store Name is required");
      return;
    }
    if (!storeData.currency_code) {
      notifyError("Store default currency is required");
      return;
    }
    if (!storeData.sub_domain) {
      notifyError("Sub domain is required");
      return;
    }
    setIsLoading(true);

    const payload = {
      ...storeData,
      pos_support: storeData.pos_support ? "ACTIVE" : "DISABLED",
      web_support: storeData.web_support ? "ACTIVE" : "DISABLED",
    };

    try {
      const res = await StoreServices.createStore(payload);
      const refreshToken = await AdminServices.refreshToken();

      handleStoreSelect(res?.data?.id, refreshToken?.data);
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      notifyError(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (storeData.name) {
      setStoreData((prev) => ({
        ...prev,
        sub_domain: storeData.name?.toLowerCase().trim().split(" ").join("-"),
      }));
    }
  }, [storeData.name]);

  if (!adminInfo) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }
  return (
    <div>
      {step === 0 && <ReadMoreScreen step={step} setStep={setStep} />}
      {step === 1 && <WelcomeScreen step={step} setStep={setStep} />}
      {step === 2 && (
        <AboutStore
          data={data?.data?.product_types}
          setStoreData={setStoreData}
          setStep={setStep}
          storeData={storeData}
        />
      )}
      {step === 3 && (
        <WhatToSell
          setStoreData={setStoreData}
          storeData={storeData}
          step={step}
          setStep={setStep}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
      {step === 4 && (
        <StoreMode
          setStoreData={setStoreData}
          storeData={storeData}
          step={step}
          setStep={setStep}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}

      {step === 5 && (
        <AddstoreName
          setStoreData={setStoreData}
          storeData={storeData}
          step={step}
          setStep={setStep}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
      {/* {step === 4 && (
        <Storedomain
          setStoreData={setStoreData}
          step={step}
          setStep={setStep}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )} */}
    </div>
  );
}
