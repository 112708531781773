import InfoIcon from "../../assets/svgs/info.svg";

export default function Hints({ hint, icon = false }) {
  return (
    <div className="text-xs flex gap-1 lg:items-center">
      {icon && (
        <img
          src={InfoIcon}
          alt=""
          className="w-3 h-3 text-gray-800 dark:text-white text-sm"
        />
      )}
      <span className="text-gray-700 dark:text-gray-400 text- font-light">
        {hint}
      </span>
    </div>
  );
}
