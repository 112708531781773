import { createContext, useState } from "react";

const BrowseThemeContext = createContext();

export const BrowseThemeProvider = ({ children }) => {
  const [selectedTheme, setSelectedTheme] = useState({});
  const [billingCycle, setBillingCycle] = useState("");
  const [extras, setExtras] = useState({});
  return (
    <BrowseThemeContext.Provider
      value={{
        selectedTheme,
        setSelectedTheme,
        billingCycle,
        setBillingCycle,
        setExtras,
        extras,
      }}
    >
      {children}
    </BrowseThemeContext.Provider>
  );
};

export default BrowseThemeContext;
