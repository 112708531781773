// localStorage
import Cookies from "js-cookie";
let activeStorage = window.localStorage; // Cookies; //
const StorageServices = {
  get(key) {
    let item = null;
    if (activeStorage === window.localStorage) {
      item = activeStorage.getItem(key);
    } else {
      item = activeStorage.get(key);
    }
    return item;
  },
  getAsync(key) {
    let item = null;
    if (activeStorage === window.localStorage) {
      item = activeStorage.getItem(key);
    } else {
      item = activeStorage.get(key);
    }
    return Promise.resolve(item);
  },
  set(key, value) {
    if (activeStorage === window.localStorage) {
      activeStorage.setItem(key, value);
    } else {
      activeStorage.set(key, value);
    }
  },
  remove(key) {
    if (activeStorage === window.localStorage) {
      activeStorage.removeItem(key);
    } else {
      activeStorage.remove(key);
    }
  },
  clearAll() {
    Cookies.remove("SB-business-token");
    Cookies.remove("SB-user-token");
    let logdedInEmail = this.get("logged-in-email");
    let theme = this.get("theme");
    let offlineSales = this.get(`SB-${activeStorage.id}-offline-sales`);
    let remember_me = this.get("SB-remember-me");
    const preservedItems = {};
    // Preserve keys that contain "intro-video"
    if (activeStorage === window.localStorage) {
      const introVideoKeys = Object.keys(activeStorage)?.filter((key) =>
        key.includes("SB-guide")
      );

      introVideoKeys.forEach((key) => {
        preservedItems[key] = this.get(key);
      });
    }

    if (activeStorage === window.localStorage) {
      activeStorage.clear();
    } else {
      Object.keys(activeStorage.get()).forEach(function (cookieName) {
        activeStorage.remove(cookieName);
      });
    }

    this.set("logged-in-email", logdedInEmail);

    this.set("SB-remember-me", remember_me);
    this.set(`SB-${activeStorage.id}-offline-sales`, offlineSales);

    // Restore preserved items
    Object.entries(preservedItems).forEach(([key, value]) => {
      this.set(key, value);
    });

    // Set default theme if not set
    if (!theme) {
      this.set("theme", "light");
    } else {
      this.set("theme", theme);
    }
  },
};

export default StorageServices;
