import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useActiveStore from "../hooks/useActiveStore";
import StorageServices from "../services/StorageServices";
import { notifyError } from "../utils/toast";

export const B2BCartContext = createContext();

export const useB2BCart = () => useContext(B2BCartContext);

export default function B2BCartContextProvider({ children }) {
  const [cart, setCart] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [totalVat, setTotalVat] = React.useState(0);
  const [subtotal, setSubtotal] = React.useState(0);
  const [variant, setVariant] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [supplier, setSupplier] = useState(false);
  const [isInitiallyFetched, setIsInitiallyFetched] = useState(false);
  const toggleCart = () => setIsOpen(!isOpen);
  const activeStore = useActiveStore();

  //Sort to ensure consistency, as user may not select variant accordingly
  const getCombinationName = (variant) => {
    if (!variant) return;
    return (
      variant
        .map((v) => v.entry)
        //   .sort()
        .join("/")
    );
  };

  const findCombinations = (product, combination) => {
    if (!product || !combination) {
      return;
    }

    const comb = product.variant_combinations?.find(
      (c) => c.name === combination
    );

    return comb;
  };

  const addToCart = (product, qty, selectedVariant) => {
    if (!product) return;

    //for product that have variants
    if (selectedVariant?.length > 0) {
      const variant = getCombinationName(selectedVariant);
      const comb = findCombinations(product, variant);
      console.log({ variant, product });
      if (!comb) return;

      if (!comb?.active) {
        notifyError("Product Variants selected is not available");
        return;
      }

      if (comb.track_quantity && qty > comb.quantity) {
        notifyError("Selected Quantity not available");
        return;
      }

      const _product = cart.find(
        (item) => item.selectedComb?.name === comb.name
      );

      if (_product) {
        return upDateExisting(_product, qty);
      }

      return addNewProduct(product, qty, comb, selectedVariant);
    } else {
      //else the product has no variant
      const _product = cart.find((item) => item.id === product.id);

      if (_product) {
        return upDateExisting(_product);
      }
      const qty = 1;
      return addNewProduct(product, qty);
    }
  };

  const addNewProduct = (product, qty, combination, selectedVariant) => {
    console.log({ product, qty, combination, selectedVariant });
    if (!product) {
      return;
    }

    // Determine the product price based on whether it's on sale or not.
    const productPrice = product.pricing.on_sales
      ? product.pricing.sales_price
      : product.pricing.current_price;

    // Determine the combination price based on whether it's on sale or not.
    const combinationPrice = combination?.on_sales
      ? combination?.sales_price
      : combination?.current_price;

    const newProduct = {
      id: product.id,
      cart_Id: (Math.random() + 1).toString(36).substring(7),
      title: product.title,
      images: product.images,
      price: productPrice,
      pricing: product.pricing,
      exempt_vat: product.exempt_vat,
      quantity: qty || 1,
      variants: selectedVariant,
      selectedComb: combination
        ? { ...combination, price: combinationPrice }
        : null,
      track_quantity: product.inventory.track_quantity,
    };

    // Add the new product to the cart.
    setCart([...cart, newProduct]);
  };

  const upDateExisting = (product, qty) => {
    if (!product) return;
    setCart((prev) =>
      prev.map((item) =>
        item.cart_Id === product.cart_Id
          ? {
              ...item,
              quantity: qty ? item.quantity + qty : +item.quantity + 1,
            }
          : item
      )
    );
  };

  const removeFromCart = (product) => {
    if (!product) return;

    setCart(cart.filter((item) => item.cart_Id !== product.cart_Id));
    setTotal(total - product.pricing.current_price);
  };

  const increaseQuantity = (product) => {
    if (!product) return;

    setCart(
      cart.map((item) => {
        if (item.cart_Id === product.cart_Id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      })
    );
  };

  const decreaseQuantity = (product) => {
    if (!product) return;

    if (product.quantity === 1) {
      return;
    }
    setCart(
      cart.map((item) => {
        if (item.cart_Id === product.cart_Id) {
          return { ...item, quantity: item.quantity - 1 };
        }
        return item;
      })
    );
  };

  useMemo(() => {
    const updateCartStorage = () => {
      if (isInitiallyFetched && supplier) {
        StorageServices.set(`SB-B2B-${supplier}-cart`, JSON.stringify(cart));
      }
    };

    const calculateTotal = () => {
      return cart.map((item) => ({
        exempt_vat: item.exempt_vat,
        quantity: item.quantity,
        price: item.variants ? item.selectedComb.price : item.price,
      }));
    };

    const calculateSubtotal = () => {
      const total = calculateTotal();
      return total.reduce((acc, item) => acc + item.price * item.quantity, 0);
    };

    const calculateVat = () => {
      if (!activeStore?.apply_vat) {
        return 0;
      }

      const total = calculateTotal();
      return total.reduce((acc, item) => {
        if (!item.exempt_vat) {
          return (
            acc + (item.price * item.quantity * activeStore.vat_percent) / 100
          );
        }
        return acc;
      }, 0);
    };

    const subtotal = calculateSubtotal();
    const vat = calculateVat();
    const total = subtotal + vat;

    updateCartStorage();
    setSubtotal(subtotal);
    setTotal(total);
    setTotalVat(vat);
  }, [
    cart,
    isInitiallyFetched,
    supplier,
    // activeStore,
    // setSubtotal,
    setTotal,
    // setTotalVat,
  ]);

  useEffect(() => {
    const prevCart = StorageServices.get(`SB-B2B-${supplier}-cart`);
    if (prevCart) {
      setCart(JSON.parse(prevCart) || []);
    }
    setIsInitiallyFetched(true);
  }, [setCart, setIsInitiallyFetched, supplier]);

  const values = {
    cart,
    total,
    addToCart,
    removeFromCart,
    increaseQuantity,
    decreaseQuantity,
    variant,
    setVariant,
    isOpen,
    setIsOpen,
    toggleCart,
    setCart,
    totalVat,
    subtotal,
    supplier,
    setSupplier,
  };
  return (
    <B2BCartContext.Provider value={values}>{children}</B2BCartContext.Provider>
  );
}
