import { HelperText } from "@windmill/react-ui";
import React from "react";
const Error = ({ errorName }) => {
  return (
    <>
      {errorName && <HelperText valid={false}>{errorName.message}</HelperText>}
    </>
  );
};

export default Error;
