import { Button, HelperText } from "@windmill/react-ui";

import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import logoGray from "../../assets/svgs/Storebounty-icon-gray.svg";
import Error from "../../components/form/Error";
import LabelArea from "../../components/form/LabelArea";
import Logo from "../../components/logo/Logo";
import useIosAuth from "../../hooks/formSubmits/useIosAuth";

const IsoLogin = () => {
  const { onLoginSubmit, register, handleSubmit, errors, loading } =
    useIosAuth();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="w-full min-h-screen bg-gray-50">
      <div className="flex flex-col items-center min-h-full justify-center max-w-7xl mx-auto">
        <Logo />
        <main className="flex flex-col justify-between items-center h-screen md:h-full p-6 sm:p-6 mx-auto max-w-lg w-full">
          <div className="w-full">
            <h1 className="mb-4 text-xl font-semibold text-gray-700 border-b text-center pb-4 mt-4 md:mt-0">
              Login
            </h1>
            <form onSubmit={(e) => handleSubmit(onLoginSubmit)(e)}>
              <div className="flex flex-col gap-1">
                <LabelArea label="Email" />
                <input
                  {...register("email", { required: true })}
                  // defaultValue={loggedInEmail}

                  className="focus:border-green-500 text-sm border focus:shadow-md p-2 focus:outline-none rounded"
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="john@doe.com"
                />
                <HelperText valid={false}>
                  {errors.email?.type === "required" &&
                    "Please enter your email"}
                </HelperText>
              </div>
              <div className="mt-3 md:mt-4 flex flex-col gap-1 relative">
                <LabelArea label="Confirm password *" />
                <input
                  {...register("password", { minLength: 4, required: true })}
                  className="focus:border-green-50 text-sm border focus:shadow-md p-2 focus:outline-none rounded h-10"
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="***************"
                  min={4}
                />
                <HelperText valid={false}>
                  {errors.password?.type === "required" &&
                    "Password is required"}
                  {errors.password?.type === "minLength" &&
                    "Password must be at least 4 characters"}
                </HelperText>

                <Error errorName={errors.password} />

                <label className={`absolute right-0 mr-3 mt-10`}>
                  {
                    <>
                      {showPassword ? (
                        <AiOutlineEye className="text-green-600 " />
                      ) : (
                        <AiOutlineEyeInvisible className="text-gray-400 " />
                      )}
                    </>
                  }

                  <input
                    className="appearance-none"
                    type="checkbox"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                </label>
              </div>
              <div className="flex justify-between lg:mt-3 mt-2 text-sm">
                <div className="flex gap-1 items-center">
                  <input
                    {...register("remember_me")}
                    type="checkbox"
                    className="w-4 h-4 bg-green-500 text-green-500 "
                  />
                  Remember me
                </div>
                <Link to={"/forgot-password"} className="text-green-500">
                  <i>Forgot Password?</i>
                </Link>
              </div>
              <hr className="my-6" />
              <Button
                disabled={loading}
                type="submit"
                className=" h-10 w-full "
              >
                Log in
              </Button>
              <div className="relative flex py-4 items-center">
                <div className="flex-grow border-t border-gray-200"></div>
                <span className="flex-shrink mx-4 text-gray-400">or</span>
                <div className="flex-grow border-t border-gray-200"></div>
              </div>
            </form>

            <p className="my-4 md:t-1 flex justify-center items-center gap-1 text-sm mx-auto">
              Don’t have an account?
              <Link
                className="text-sm font-medium text-green-500  hover:underline"
                to="/signup/ios"
              >
                Signup
              </Link>
            </p>
          </div>{" "}
          <img
            className="self-start justify-self-start w-48 mx-auto md:hidden mb-12"
            src={logoGray}
            alt=""
          />
        </main>
      </div>
    </div>
  );
};

export default IsoLogin;
