import axios from "axios";
import Cookies from "js-cookie";
import StorageServices from "./StorageServices";

export const uninterceptedAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 500000,
});

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const multipartInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  var userToken;
  if (Cookies.get("SB-user-token")) {
    userToken = Cookies.get("SB-user-token");
  }
  if (Cookies.get("SB-business-token")) {
    userToken = Cookies.get("SB-business-token");
  }
  var token = userToken ? userToken.replace(/['"]+/g, "") : null;
  //console.log('Token - interecepted: ', token);
  const headers = {
    ...config,
    headers: {
      Authorization: token,
      clientId: "storebounty", //`${process.env.APP_ID}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
  //console.log('Headers - interecepted: ', JSON.stringify(headers));
  return headers;
});

multipartInstance.interceptors.request.use(function (config) {
  var userToken;
  if (Cookies.get("SB-user-token")) {
    userToken = Cookies.get("SB-user-token");
  }
  if (Cookies.get("SB-business-token")) {
    userToken = Cookies.get("SB-business-token");
  }
  var token = userToken ? userToken.replace(/['"]+/g, "") : null;
  //console.log('Token - interecepted: ', token);
  const headers = {
    ...config,
    headers: {
      Authorization: token,
      clientId: "storebounty", //`${process.env.APP_ID}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
  //console.log('Headers - interecepted: ', JSON.stringify(headers));
  return headers;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      StorageServices.clearAll();

      if (window.location.pathname !== "/login") {
        if (window.location.pathname.includes("ios")) {
          window.location = "/login/ios";
        } else {
          window.location = "/login";
        }
      }

      return Promise.reject(error);
    } else if (error?.response?.status === 403) {
      window.location = "/unauthorized";
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

multipartInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      StorageServices.clearAll();

      if (window.location.pathname !== "/login") {
        if (window.location.pathname.includes("ios")) {
          window.location = "/login/ios";
        } else {
          window.location = "/login";
        }
      }

      return Promise.reject(error);
    } else if (error?.response?.status === 403) {
      window.location = "/unauthorized";
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const responseBody = (response) => response.data || response;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  getBlob: (url) => instance.get(url, { responseType: 'blob' }).then(response => response),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  postMultipart: (url, payload, file) => {
    const formData = new FormData();
    formData.append('payload', new Blob([JSON.stringify(payload)], { type: "application/json" }));
    if (file) {
      formData.append('file', file);
    }
    return multipartInstance.post(url, formData).then(responseBody);
  },

  putMultipart: (url, payload, file) => {
    const formData = new FormData();
    formData.append('payload', new Blob([JSON.stringify(payload)], { type: "application/json" }));
    if (file) {
      formData.append('file', file);
    }
    return multipartInstance.put(url, formData).then(responseBody);
  },

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body, headers) =>
    instance.delete(url, body, headers).then(responseBody),
};

export default requests;
