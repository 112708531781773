import requests from "./httpService";
import StorageServices from "./StorageServices";

const ModuleServices = {
  getModules() {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.get(`/marketplace/v1/stores/${storeId || 0}/modules`);
  },
  updateModules(payload) {
    const storeId = StorageServices.get("SB-Active-Store-Id");
    return requests.put(`/marketplace/v1/stores/${storeId}/modules`, payload);
  },
};

export default ModuleServices;
