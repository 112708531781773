import "@pathofdev/react-tag-input/build/index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Windmill } from "@windmill/react-ui";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import "./assets/css/custom.css";
import "./assets/css/tailwind.css";
import "./assets/css/tailwind.output.css";
import myTheme from "./assets/theme/myTheme";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import { AdminProvider } from "./context/AdminContext";
import B2BCartContextProvider from "./context/B2BContext";
import { BrowseThemeProvider } from "./context/BrowseThemeContext";
import { HelpsAndGuidesProvider } from "./context/HelpsAndGuidesContext";
import { NotificationProvider } from "./context/NotificationContext";
import { SidebarProvider } from "./context/SidebarContext";
import StorageServices from "./services/StorageServices";
import swDev from "./swDev";
// if (process.env.NODE_ENV !== "production") {
//   const axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

Sentry.init({
  dsn: "https://995734d5a27a438d83f29d4bde8a2274@o4504609967702016.ingest.sentry.io/4504610031337472",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

//App Version
const expectedAppVersion = 7;
const savedAppVersion = StorageServices.get("appVersion");

//Logout User if App version is < expectedAppVersion >
if (savedAppVersion !== null) {
  const parsedSavedAppVersion = parseInt(savedAppVersion, 10);

  //APP version is greater than expected version - LOGOUT
  // if (parsedSavedAppVersion > expectedAppVersion) {
  //   StorageServices.clearAll();
  //   window.location.replace("/");
  //   StorageServices.set("appVersion", expectedAppVersion);
  // }

  if (parsedSavedAppVersion !== expectedAppVersion) {
    StorageServices.clearAll();
    StorageServices.set("appVersion", expectedAppVersion);
    if (window.location.pathname.includes("ios")) {
      window.location = "/login/ios";
    } else {
      window.location = "/login";
    }
  }
} else {
  // User does not have an APP version - LOGOUT
  StorageServices.clearAll();
  StorageServices.set("appVersion", expectedAppVersion);
  if (window.location.pathname.includes("ios")) {
    window.location = "/login/ios";
  } else {
    window.location = "/login";
  }
}

// Rest of your app code

ReactDOM.render(
  <HelmetProvider>
    <BrowseThemeProvider>
      <AdminProvider>
        <SidebarProvider>
          <NotificationProvider>
            <HelpsAndGuidesProvider>
              <B2BCartContextProvider>
                <Suspense fallback={<ThemeSuspense />}>
                  <Windmill usePreferences theme={myTheme}>
                    <App />
                  </Windmill>
                </Suspense>
              </B2BCartContextProvider>
            </HelpsAndGuidesProvider>
          </NotificationProvider>
        </SidebarProvider>
      </AdminProvider>
    </BrowseThemeProvider>
  </HelmetProvider>,

  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

swDev();
// serviceWorker.register();
