import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import AdminServices from "../../services/AdminServices";
import StoreServices from "../../services/StoreServices";
import { notifyError, notifySuccess } from "../../utils/toast";
import useSelectStore from "../useSelectStore";

const useStoreSubmit = ({ handleClose }) => {
  const [productType, setProductType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { handleStoreSelect } = useSelectStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm({
    defaultValues: {
      store_module: "general_store",
    },
  });

  const storeName = watch("name", "");
  const currency_code = watch("currency_code", "");
  const sub_domain = watch("sub_domain", "");
  const web_support = watch("web_support", "");
  const pos_support = watch("pos_support", "");
  const mode = watch("mode", "");
  const test_product = watch("test_product", "");
  const store_module = watch("store_module", "");
  const moduleObj = watch("moduleObj", {});

  useEffect(() => {
    if (storeName) {
      setValue(
        "sub_domain",
        storeName.toLowerCase()?.trim()?.split(" ").join("-")
      );
    }
  }, [storeName]);

  useEffect(() => {
    setValue("currency_code", "");
    setValue("web_support", true);
    setValue("pos_support", true);
    setValue("mode", "test");
    setValue("test_product", true);
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const res = await StoreServices.createStore(data);
      const refreshToken = await AdminServices.refreshToken();
      handleClose();
      if (res.code === 200) {
        notifySuccess(res.message);
        handleStoreSelect(res?.data?.id, refreshToken?.data);
      }
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      notifyError(message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    productType,
    setProductType,
    web_support,
    pos_support,
    currency_code,
    sub_domain,
    test_product,
    mode,
    Controller,
    control,
    watch,
    setValue,
    store_module,
    moduleObj,
  };
};

export default useStoreSubmit;
