import { useState } from "react";
import AdminServices from "../../services/AdminServices";
import StoreServices from "../../services/StoreServices";
import { notifyError } from "../../utils/toast";
import useSelectStore from "../useSelectStore";

export default function useStoreInvite(data) {
  const [inviting, setInviting] = useState(false);
  const [declining, setDeclining] = useState(false);
  const { handleStoreSelect } = useSelectStore();

  const onSubmit = async (data) => {
    try {
      data.accepted && setInviting(true);
      !data.accepted && setDeclining(true);

      const payload = {
        accepted: data.accepted,
        invite_id: data.invite_id,
      };
      await StoreServices.acceptRejectInvite(payload);
      const refreshToken = await AdminServices.refreshToken();

      handleStoreSelect(data.business_code, refreshToken?.data);
    } catch (error) {
      const message =
        error.response?.data?.message || error.message || error.toString();
      notifyError(message);
    } finally {
      setInviting(false);
      setDeclining(false);
    }
  };
  return {
    onSubmit,
    inviting,
    declining,
  };
}
