import { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [notificationData, setNotificationData] = useState();
  const [showNotification, setShowNotification] = useState(false);

  const value = {
    notificationData,
    setNotificationData,
    showNotification,
    setShowNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationContext;

export const useNotificationAlert = () => {
  return useContext(NotificationContext);
};
