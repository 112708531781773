import React from "react";

export default function OnBoardingContainer({ children }) {
  return (
    <div
      className="bg-white  rounded "
      style={{
        filter: "drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.04))",
      }}
    >
      {children}
    </div>
  );
}
