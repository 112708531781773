import React from "react";
import { FadeLoader } from "react-spinners";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";

export default function IsoSuccess() {
  return (
    <div className="">
      <div className="min-h-screen overflow-auto  bg-green-50 py-20 px-3">
        <div className="container mx-auto">
          <div className="max-w-lg mx-auto ">
            <div className="w-full">
              <div className="bg-white py-3 rounded shadow-lg text-center text-sm">
                <img
                  src={Storebounty_logo}
                  alt=""
                  className="w-40 h-auto mx-auto "
                />

                <div className="h-48   flex justify-center items-center">
                  <FadeLoader color="#0230B1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
