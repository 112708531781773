
const webCurrencyCodes = {
    USD: "USD",
    GBP: "GBP", // British Pound Sterling
    AED: "AED", // United Arab Emirates Dirham
    NGN: "NGN",
    KES: "KES", // Kenyan Shilling
    GHS: "GHS",
    ZAR: "ZAR", // South African Rand
    EGP: "EGP", // Egyptian Pound
    CAD: "CAD",
    RWF: "RWF", // Rwandan Franc
};

export default webCurrencyCodes;