import APPOINTMENT_PNG from "../assets/illustrations/APPOINTMENT.jpg";
import Expenses_jpg from "../assets/illustrations/Expenses.jpg";
import INVENTORY_jpg from "../assets/illustrations/INVENTORY.jpg";
import MESSAGING_jpg from "../assets/illustrations/MESSAGING.jpg";
import Marketing_jpg from "../assets/illustrations/Marketing.jpg";
import Marketplace_jpg from "../assets/illustrations/Marketplace.jpg";
import ONLINE_jpg from "../assets/illustrations/ONLINE.jpg";
import POS_jpg from "../assets/illustrations/POS.jpg";
import WHOLESALES_jpg from "../assets/illustrations/WHOLESALES.jpg";
import FoodMenu_jpg from "../assets/illustrations/food-menu.png";

export const INVOICE_MODALS = {
  products: "products",
  editProducts: "editProduct",
  discount: "discount",
  payment: "payment",
  customer: "customer",
};

export const paymentStatusOptions = [
  { label: "Fully Paid", value: "instant", id: "instant" },
  { label: "Part Payment", value: "part_instant", id: "part_instant" },
  { label: "Credit Purchase", value: "credit", id: "credit" },
  { label: "Pay on Delivery", value: "pod", id: "pod" },
];

export const paymentMethods = [
  {
    label: "Cash",
    id: "Cash",
    value: "Cash",
  },
  {
    label: "Bank Transfer",
    id: "Bank Transfer",
    value: "Bank Transfer",
  },
  {
    label: "ussd",
    id: "ussd",
    value: "ussd",
  },
  {
    label: "Cheque",
    id: "Cheque",
    value: "Cheque",
  },
  {
    label: "Mobile Money",
    id: "Mobile Money",
    value: "Mobile Money",
  },
  {
    label: "Others",
    id: "Others",
    value: "Others",
  },
];

export const eventLocation = [
  {
    label: "Physical Location",
    value: "offline",
    id: "offline",
  },
  {
    label: "Online Event",
    value: "online",
    id: "online",
  },
];

export const eventType = [
  {
    label: "Personal Event",
    value: "PERSONAL",
    id: "PERSONAL",
  },
  {
    label: "Appointment with customers",
    value: "APPOINTMENT",
    id: "APPOINTMENT",
  },
];

export const modules = {
  module_appointment: {
    title: "Appointments",
    image: APPOINTMENT_PNG,
  },
  module_expenses: {
    title: "Expenses",
    image: Expenses_jpg,
  },
  module_food_menu: {
    title: "Food Menu",
    image: FoodMenu_jpg,
  },
  module_inventory: {
    title: "Inventory Management",
    image: INVENTORY_jpg,
  },
  module_marketing: {
    title: "Marketing",
    image: Marketing_jpg,
  },
  module_marketplace: {
    title: "Market Place",
    image: Marketplace_jpg,
  },
  module_messaging: {
    title: "Messaging",
    image: MESSAGING_jpg,
  },
  module_online: {
    title: "Online Checkout",
    image: ONLINE_jpg,
  },
  module_pos: {
    title: "Point of Sales",
    image: POS_jpg,
  },
  module_wholesale: {
    title: "Wholesales (B2B)",
    image: WHOLESALES_jpg,
  },
};
export const modulesImages = {
  module_appointment: APPOINTMENT_PNG,
  module_expenses: Expenses_jpg,
  module_food_menu: FoodMenu_jpg,
  module_inventory: INVENTORY_jpg,
  module_marketing: Marketing_jpg,
  module_marketplace: Marketplace_jpg,
  module_messaging: MESSAGING_jpg,
  module_online: ONLINE_jpg,
  module_pos: POS_jpg,
  module_wholesale: WHOLESALES_jpg,
};

export const staffRoles = [
  {
    label: "Inventory Officer",
    value: "inventory_officer",
    id: "inventory_officer",
  },
  {
    label: "Sales Associate",
    value: "associate",
    id: "associate",
  },
  {
    label: "Sales Supervisor",
    value: "supervisor",
    id: "supervisor",
  },
  {
    label: "Store Manager",
    value: "manager",
    id: "manager",
  },
  {
    label: "Admin",
    value: "admin",
    id: "admin",
  },
];
