import { Button, Input, Label } from "@windmill/react-ui";
import React from "react";
import Image2 from "../../assets/illustrations/online-shopping-store.png";
import Image1 from "../../assets/illustrations/online-store.svg";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";
import ProgressBar from "../progressbar/ProgressBar";
import Hints from "../question/Question";
import BackButton from "./BackButton";
import OnBoardingContainer from "./onBoardingContainer";

export default function WhatToSell({
  onSubmit,
  setStoreData,
  isLoading,
  setStep,
  storeData,
}) {
  return (
    <div>
      <div className="min-h-screen bg-green-50 flex justify-center items-center px-3">
        <div className="container mx-auto">
          <div className="w-full max-w-4xl mx-auto">
            <OnBoardingContainer>
              <div className="p-6 lg:p-8 lg:px-10">
                <img
                  src={Storebounty_logo}
                  alt=""
                  className="w-48 h-auto mb-6"
                />
                <ProgressBar level={"60%"} />

                <h1 className="font-medium text-lg mt-6">
                  Where do you currently or would you like to sell?
                </h1>
                <p className="text-xs mt-1 text-gray-500  w-8/12">
                  Leave the box checked to allow you sell via multiple sales
                  channel.
                </p>
                <fieldset className=" gap-4 mt-2">
                  <label htmlFor="online_store">
                    <div className="flex items-cente gap-2 text-left px-4 mt-4  border p-2 rounded max-w-2xl py-8">
                      <input
                        type="checkbox"
                        className="w-4 h-4 min-w-4 mt-1"
                        id="online_store"
                        value={storeData.web_support}
                        checked={storeData.web_support}
                        onChange={(e) =>
                          setStoreData((prev) => ({
                            ...prev,
                            web_support: !prev.web_support,
                          }))
                        }
                      />
                      <div className="flex gap-2">
                        <img
                          src={Image1}
                          alt=""
                          className="w-12 lg:w-12 h-auto object-contain"
                        />
                        <div className="">
                          <p className="text-sm font-medium">Online Store</p>
                          <span className="text-xs text-gray-600">
                            Your online store allows you sell your products via
                            web sales channel(s) with your custom domain.
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                  <label
                    htmlFor="pos_support"
                    className="block px-4 mt-4 border p-2 rounded max-w-2xl py-8"
                  >
                    <div className="flex items-center gap-2 text-left">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        id="pos_support"
                        value={storeData.pos_support}
                        checked={storeData.pos_support}
                        onChange={(e) =>
                          setStoreData((prev) => ({
                            ...prev,
                            pos_support: !prev.pos_support,
                          }))
                        }
                      />
                      <div className="flex gap-2 ">
                        <img
                          src={Image2}
                          alt=""
                          className="w-20 h-auto object-contain"
                        />
                        <div className="">
                          <p className="text-sm font-medium">In Person</p>
                          <span className="text-xs text-gray-600">
                            Point of Sale allows you and your staff to record
                            sales manually.
                          </span>
                        </div>
                      </div>
                    </div>
                    {storeData.pos_support && (
                      <div className="ml-8 mt-4  divide-y divide-gray-100">
                        <label
                          htmlFor="general_store"
                          className="flex gap-2 py-3 cursor-pointer"
                        >
                          <Input
                            type="radio"
                            name="store_module"
                            id="general_store"
                            value={"general_store"}
                            checked={storeData.store_module === "general_store"}
                            onChange={(e) =>
                              setStoreData((prev) => ({
                                ...prev,
                                store_module: e.target.value,
                              }))
                            }
                          />
                          <div>
                            <Label>Retail Point of Sale</Label>
                            <Hints hint={"For general stores"} />
                          </div>
                        </label>
                        <label
                          htmlFor="restaurant"
                          className="flex gap-2 py-3 cursor-pointer"
                        >
                          <Input
                            type="radio"
                            name="store_module"
                            id="restaurant"
                            value={"restaurant"}
                            checked={storeData.store_module === "restaurant"}
                            onChange={(e) =>
                              setStoreData((prev) => ({
                                ...prev,
                                store_module: e.target.value,
                              }))
                            }
                          />
                          <div>
                            <Label>Restaurant Point of Sale</Label>
                            <Hints hint={"For restaurants and bars"} />
                          </div>
                        </label>
                      </div>
                    )}
                  </label>
                  <div className=" mt-12 px-4 w-full mx-auto flex justify-between">
                    <BackButton setStep={setStep} />
                    <Button
                      disabled={
                        !storeData.pos_support && !storeData.web_support
                      }
                      type="button"
                      onClick={() => setStep((prev) => prev + 1)}
                      className=" lg:px-12 px-6 my-6"
                      style={{ borderRadius: "4px" }}
                    >
                      Continue
                    </Button>
                  </div>
                </fieldset>
              </div>
            </OnBoardingContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
