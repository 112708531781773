import { Button } from "@windmill/react-ui";
import React from "react";
import logo from "../../assets/img/Storebounty_icon.png";
import IgIcon from "../../assets/svgs/instagram-ic.svg";
import fbIcon from "../../assets/svgs/facebook-ic.svg";
import twIcon from "../../assets/svgs/twitter-ic.svg";
import ytIcon from "../../assets/svgs/youtube-ic.svg";
export default function ReadMoreScreen({ setStep }) {
  return (
    <div>
      <div>
        <img src={logo} alt="" className="w-48" />
        <div className="flex justify-end mt-2 mb-4 px-4">
          <Button className="bg-yellow-700" onClick={() => setStep(3)}>
            close
          </Button>
        </div>
        <div className="bg-gray-400 h-64 w-full"></div>
        <main className="max-w-2xl text-justify mx-auto text-sm">
          <p className="text-lg py-4">Introduction</p>
          <p>
            Et has minim elitr intellegat. eleifend antiopam ad, nam no suscipit
            quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.
            Ex duo eripuit mentitum. Read more. Et has minim elitr intellegat.
            eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum
            ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum.
            Read more. Et has minim elitr intellegat. eleifend antiopam ad, nam
            no suscipit quaerendum. At nam minimum ponderum. Est audiam animal
            molestiae te. Ex duo eripuit mentitum. Read more. Et has minim elitr
            intellegat. eleifend antiopam ad, nam no suscipit quaerendum. At nam
            minimum ponderum. Est audiam animal molestiae te. Ex duo eripuit
            mentitum. Read more.
          </p>
          <p className="mt-4">
            Et has minim elitr intellegat. eleifend antiopam ad, nam no suscipit
            quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.
            Ex duo eripuit mentitum. Read more. Et has minim elitr intellegat.
            eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum
            ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum.
            Read more. Et has minim elitr intellegat. eleifend antiopam ad, nam
            no suscipit quaerendum. At nam minimum ponderum. Est audiam animal
            molestiae te. Ex duo eripuit mentitum.
          </p>
          <p className="mt-4">
            Et has minim elitr intellegat. eleifend antiopam ad, nam no suscipit
            quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.
            Ex duo eripuit mentitum. Read more. Et has minim elitr intellegat.
            eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum
            ponderum. Est audiam animal molestiae te. Ex duo eripuit mentitum.
            Read more. nam minimum ponderum. Est audiam animal molestiae te. Ex
            duo eripuit mentitum. Et has minim elitr intellegat. eleifend
            antiopam ad, nam no suscipit quaerendum. At nam minimum ponderum.
            Est audiam animal molestiae te. Ex duo eripuit mentitum. Read more.
            Et has minim elitr intellegat. eleifend antiopam ad, nam no suscipit
            quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.
            Ex duo eripuit mentitum. Read more. nam minimum ponderum. Est audiam
            animal molestiae te. Ex duo eripuit mentitum.
          </p>
          <p className="mt-4">
            Et has minim elitr intellegat. eleifend antiopam ad, nam no suscipit
            quaerendum. At nam minimum ponderum. Est audiam animal molestiae te.
            Ex duo eripuit mentitum. Read more. Et has minim elitr intellegat.
            eleifend antiopam ad, nam no suscipit quaerendum. At nam minimum
            ponderum. Est audiam animal
          </p>
          <p className="mt-8">Follow Our Social Media Platform</p>
          <div className="flex gap-4 my-4">
            <img src={IgIcon} alt="" className="w-6" />
            <img src={fbIcon} alt="" className="w-6" />
            <img src={twIcon} alt="" className="w-6" />
            <img src={ytIcon} alt="" className="w-6" />
          </div>
        </main>
      </div>
    </div>
  );
}
