import { Button } from "@windmill/react-ui";
import React from "react";
import logo from "../../assets/img/Storebounty_icon.png";
import helloImg from "../../assets/img/say-hello-to-new-people.png";
import LogoutBtn from "../common/LogoutBtn";
export default function WelcomeScreen({ setStep }) {
  return (
    <div className="p-6 lg:p-0">
      <div className="flex justify-between items-center lg:mx-24 mt-8 mb-6">
        <img src={logo} alt="" className="  logo " />
        <LogoutBtn />
      </div>
      <div className="flex flex-col justify-between max-w-2xl mx-auto">
        <div>
          <img className="w-4/6 mx-auto" src={helloImg} alt="welcome" />
          <p className="text-green-500 font-semibold text-3xl">
            Welcome to Store Bounty
          </p>
          <p className="max-w-lg text-sm mt-3">
            We're happy to have you join the family. Please follow the next few
            steps to get your store completely set up for free.
            {/* <Link
              to="#"
              className="text-green-500"
              onClick={() => {
                setStep(0);
              }}
            >
              Read more.
            </Link> */}
          </p>
        </div>

        <div className="flex justify-end border-t border-gray-200 mt-4 py-6">
          <Button
            onClick={() => {
              setStep((prev) => prev + 1);
            }}
            className="px-12"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
