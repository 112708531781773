import React from "react";

export default function ProgressBar({ level = "25%" }) {
  return (
    <>
      <div className="relative max-h-3">
        <div class="w-full my-2 bg-gray-200 rounded-full h-1 mt-1  dark:bg-gray-600"></div>
        <div
          class="bg-green-600 h-2 rounded-full absolute"
          style={{ width: level, marginTop: "-14px" }}
        ></div>{" "}
      </div>
    </>
  );
}
