import React, { useContext } from "react";
import { MdLogout } from "react-icons/md";
import { AdminContext } from "../../context/AdminContext";

export default function LogoutBtn() {
  const { dispatch } = useContext(AdminContext);

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
    window.location.href = "/";
  };
  return (
    <button
      className="text-red-700 font-medium text-sm focus:outline-none flex items-center gap-2"
      onClick={() => handleLogOut()}
    >
      <MdLogout /> Logout
    </button>
  );
}
