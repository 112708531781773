import { Switch } from "@headlessui/react";
import React from "react";
import { HiViewGridAdd } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
export default function ModuleCard({
  title,
  value,
  name,
  handleChange,
  saving,
  image,
  description,
}) {
  return (
    <div className="bg-white dark:bg-gray-800 dark:text-gray-300 p-4 rounded-lg">
      <div className="flex justify-center items-center h-40 bg-gray-100 dark:bg-gray-700 rounded-lg overflow-hidden">
        {image ? (
          <img src={image} alt="" className="object-contain " />
        ) : (
          <HiViewGridAdd className="w-32 h-32 text-gray-200" />
        )}
      </div>
      <h2 className="font-medium mt-4">{title}</h2>
      <p className="text-xs mt-2">{description}</p>

      <div className="mt-3 flex justify-between items-center">
        <p className="text-xs font-medium">
          {value === "active" ? "Disable" : "Enable"} Module{" "}
        </p>

        {saving === name ? (
          <ClipLoader size={20} color="#0230B1" />
        ) : (
          <Switch
            checked={value === "active"}
            disabled={saving}
            onChange={(e) => handleChange(name, e)}
            className={`${
              value === "active" ? "bg-green-600" : "bg-gray-300"
            } relative inline-flex h-6 w-12 items-center rounded-full focus:outline-none`}
          >
            <span className="sr-only">Enable notifications</span>
            <span
              className={`${
                value === "active" ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-5 transform rounded-full bg-white`}
            />
          </Switch>
        )}
      </div>
    </div>
  );
}
