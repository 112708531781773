import React, { useContext, useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { AdminContext } from "../../context/AdminContext";
import StorageServices from "../../services/StorageServices";

const PrivateRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = useContext(AdminContext);
  const { adminInfo } = state;
  const activeStore = StorageServices.get("SB-Active-Store")
    ? JSON.parse(StorageServices.get("SB-Active-Store"))
    : "";

  useEffect(() => {
    if (adminInfo && !adminInfo?.email_verified) {
      history.push("/verify");
      return;
    }
    if (adminInfo && !activeStore && location.pathname !== "select-store") {
      history.push("/select-store");
    }
  }, [adminInfo, activeStore, location, history]);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        adminInfo ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
