import React from "react";
import { BiChevronLeft } from "react-icons/bi";

export default function BackButton({ setStep = () => {} }) {
  return (
    <button
      onClick={() => setStep((prev) => prev - 1)}
      className="flex items-center text-gray-800  text-sm focus:outline-none hover:text-green-500"
    >
      <BiChevronLeft className="mr-1 w-4 h-4" />
      Back
    </button>
  );
}
