import React from "react";

import { BsChevronUp } from "react-icons/bs";
import useActiveStore from "../../hooks/useActiveStore";
import useOnClickOutside from "../../hooks/useOutSideClick";
import { notifyError } from "../../utils/toast";

export default function SelectProductType({
  data,
  register,
  watchProductType,
  productType,
  setProductType,
  enableEnterprice = false,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));
  const store = useActiveStore();

  return (
    <div>
      <div className="relative " ref={ref}>
        <div>
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="inline-flex justify-between items-center w-full rounded border border-gray-200 shadow-sm px-4 h-10 bg-white dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <p>{productType ? productType.title : "Select product type"}</p>
            {isOpen ? (
              <BsChevronUp className=" text-sm" />
            ) : (
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>
        </div>
        {isOpen && (
          <div className="origin-top-right inset-y-auto absolute z-50 right-0 -mt-3 lg:ml-6 lg:mr-16 rounded-lg shadow-xl  bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <fieldset className="overflow-auto py-4 pb-64 h-128 flex flex-col gap-1">
              {/* <Scrollbars> */}
              {data &&
                data.map((option, i) => (
                  <label
                    onClick={() => {
                      if (
                        enableEnterprice &&
                        store.contract_type === "basic" &&
                        option.contract_type !== "basic"
                      ) {
                        notifyError(
                          `${option.title} is only available to Enterprise plan
                          users `
                        );
                        return;
                      }
                      setProductType(option);
                    }}
                    htmlFor={option.id}
                    key={option.id}
                  >
                    <div
                      className={`${
                        watchProductType == option.id &&
                        "bg-green-50 dark:bg-gray-800 "
                      } hover:bg-green-50 dark:hover:bg-gray-800 px-8 pt-2 pb-3 `}
                    >
                      <p className="text-green-500 text-sm dark:text-white ">
                        {option.title}
                        {store.contract_type === "basic" &&
                          option.contract_type !== "basic" && (
                            <span
                              className=" bg-purple-600 text-white text-xs rounded-md ml-1"
                              style={{ padding: "2px 6px" }}
                            >
                              Enterprise Plan
                            </span>
                          )}
                      </p>
                      <input
                        {...register("product_type_id", {
                          onChange: (e) => {
                            setIsOpen(false);
                          },
                        })}
                        type="radio"
                        value={option.id}
                        className="hidden"
                        name={"product_type_id"}
                        id={option.id}
                        disabled={
                          enableEnterprice &&
                          store.contract_type === "basic" &&
                          option.contract_type !== "basic"
                        }
                      />

                      <span className="text-xs dark:text-gray-400">
                        {option.description}
                      </span>
                    </div>
                  </label>
                ))}
              {/* </Scrollbars> */}
            </fieldset>
          </div>
        )}
      </div>
    </div>
  );
}
