import { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../context/SidebarContext";

const useToggleDrawer = () => {
  const [serviceId, setServiceId] = useState("");
  const { toggleDrawer, isDrawerOpen, toggleModal, setCreate } =
    useContext(SidebarContext);

  const handleUpdate = (id) => {
    setServiceId(id);
    toggleDrawer();
  };

  const handleModalOpen = (id) => {
    setServiceId(id);
    toggleModal();
  };

  const handleCreateOpen = (id) => {
    setCreate(true);
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      setServiceId();
    }
  }, [isDrawerOpen]);

  return {
    serviceId,
    handleModalOpen,
    handleCreateOpen,
    handleUpdate,
    setServiceId,
    toggleDrawer,
  };
};

export default useToggleDrawer;
