import { Button } from "@windmill/react-ui";
import React, { useContext } from "react";
import PinInput from "react-pin-input";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Storebounty_logo from "../../assets/img/logo-new.png";
import { AdminContext } from "../../context/AdminContext";
import useAuth from "../../hooks/formSubmits/useAuth";
import StorageServices from "../../services/StorageServices";

export default function EmailVerification({ setStep }) {
  const email = StorageServices.get("logged-in-email");

  const { state, dispatch } = useContext(AdminContext);

  const { adminInfo } = state;
  const history = useHistory();

  const handleLogOut = () => {
    dispatch({ type: "USER_LOGOUT" });
  };
  const {
    onVerifyEmail,
    Controller,
    control,
    handleSubmit,
    // errors,
    onsendEmailOtp,
    loading,
    reset,
  } = useAuth();

  let ele = React.useRef(null);
  const onClear = () => {
    ele.clear();
  };

  if (!adminInfo) {
    history.push("/login");
  }
  if (adminInfo?.email_verified) {
    history.push("/");
  }
  return (
    <div className="">
      <div className="min-h-screen overflow-auto  bg-green-50 py-20 px-3">
        <div className="container mx-auto">
          <div className="max-w-lg mx-auto ">
            <div className="w-full">
              <div className="bg-white  rounded shadow-lg text-center text-sm">
                <img
                  src={Storebounty_logo}
                  alt=""
                  className="w-32 h-auto mx-auto"
                />
                <h1 className="font-medium text-base mt-4">
                  Please verify your account
                </h1>
                <p className="text-sm mt-4">Enter the 4-digit OTP sent to</p>
                <p className="text-xs mt-1 font-medium ">{email && email}</p>
                <form onSubmit={handleSubmit(onVerifyEmail)}>
                  <Controller
                    control={control}
                    name="otp"
                    render={({ field: { onChange, ref, value } }) => (
                      <PinInput
                        length={4}
                        initialValue=""
                        secret
                        ref={(n) => (ele = n)}
                        value={value}
                        onChange={onChange}
                        type="numeric"
                        inputMode="number"
                        style={{
                          padding: "10px",
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          margin: "20px 0px",
                        }}
                        inputStyle={{
                          borderColor: "#c3ddfd",
                          borderRadius: "4px",
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          width: "60px",
                          height: "60px",
                        }}
                        inputFocusStyle={{
                          borderColor: " #0230B1",
                          // border: "1px solid rgba(9, 29, 143, 0.3)",
                          boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
                          borderRadius: "4px",
                        }}
                        // onComplete={(value, index) => {}}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                      />
                    )}
                  />

                  <Button
                    layout="link"
                    className="tex-xs text-red-700"
                    onClick={() => {
                      reset();
                      onClear();
                    }}
                  >
                    Clear
                  </Button>
                  <div></div>
                  <Button
                    type="submit"
                    className="px-24 my-6"
                    style={{
                      borderRadius: "4px",
                    }}
                  >
                    Confirm
                  </Button>
                </form>

                <div className="mt-5 w-4/6 mx-auto">
                  <Button
                    disabled={loading}
                    layout="link"
                    className="text-xs mb-4 "
                    onClick={() => onsendEmailOtp({ email })}
                  >
                    <a>
                      Did not receive OTP?
                      <span className="text-green-500 "> Resend OTP</span>
                    </a>
                    {loading && <ClipLoader size={20} color="white" />}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex justify-center">
          <button className="text-sm" onClick={handleLogOut}>
            Wrong email?
            <span className="text-green-500  underline ml-1">Start over</span>
          </button>
        </div>
      </div>
    </div>
  );
}
