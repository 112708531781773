import { Button } from "@windmill/react-ui";
import React from "react";
import logo from "../../assets/img/Storebounty_icon.png";
import { notifyError } from "../../utils/toast";
import ProgressBar from "../progressbar/ProgressBar";
import OnBoardingContainer from "./onBoardingContainer";

export default function AboutStore({ setStep, data, setStoreData, storeData }) {
  const [active, setActive] = React.useState("");

  return (
    <div className="h-screen bg-green-50">
      <div className="max-w-4xl mx-auto py-12 ">
        <OnBoardingContainer>
          <div className="text-left p-6 lg:p-8 lg:px-10 pt-6">
            <a href={process.env.REACT_APP_SB_HOME_URL}>
              <img src={logo} alt="" className="  logo mb-6 " />
            </a>
            <ProgressBar level={"40%"} />

            <div>
              <p className="text-lg font-medium mt-6">
                What type of product do you want to sell?
              </p>
              <p className="text-sm mt-1">
                Select the type of products to be sold in your store: You can
                only select one.
              </p>

              <div className="mt-6">
                <div className=" grid lg:grid-cols-2 gap-4">
                  {data &&
                    data.map((option, i) => (
                      <label
                        onClick={() => setActive(option.title)}
                        htmlFor={option.id}
                        key={option.id}
                        className="border border-gray-200 rounded block"
                      >
                        <div
                          className={` px-4 py-3 hover:bg-green-50 flex align-center items-center gap-3`}
                        >
                          <input
                            onChange={(e) =>
                              setStoreData((prev) => ({
                                ...prev,
                                product_type_id: e.target.value,
                              }))
                            }
                            type="radio"
                            value={option.id}
                            className="focus:outline-none min-w-5 h-5 mt-2 "
                            name={"product_type_id"}
                            id={option.id}
                            checked={storeData.product_type_id == option.id}
                          />
                          <div>
                            <p className="text-sm font-medium">
                              {option.title}
                            </p>
                            <p className="text-xs dark:text-gray-400 leading-1 mt-1">
                              {option.description}
                            </p>
                          </div>
                        </div>
                      </label>
                    ))}
                </div>
              </div>
            </div>

            <div className="flex justify-end py-4 pb-0 bottom-0 w-full px-4 lg:px-0">
              <Button
                onClick={() => {
                  if (!storeData.product_type_id) {
                    notifyError("please select a product type");
                    return;
                  }
                  setStep((prev) => prev + 1);
                }}
                className="px-12"
              >
                Continue
              </Button>
            </div>
          </div>
        </OnBoardingContainer>
      </div>
    </div>
  );
}
