import React from "react";
import logo from "../../assets/img/Storebounty_icon.png";

export default function Logo({
  className,
  margin = "  hidden md:block lg:ml-24 mt-8 mb-6",
}) {
  return (
    <a
      href={process.env.REACT_APP_SB_HOME_URL}
      className={`self-start justify-self-start logo ${margin}`}
    >
      <img src={logo} alt="" className={className} />
    </a>
  );
}
