import { createContext, useEffect, useState } from "react";
import StorageServices from "../services/StorageServices";

// create context
export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showSideBar, setShowSideBar] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  // const [collapsed, setCollapsed] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  //the next line controls overflow/absolute positioning of the sidebar(wrong naming)
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [create, setCreate] = useState(false); // this should be the [isCreateOpen]
  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const toggleCollapse = () => {
    StorageServices.set("SB-Sidebar-Collapse", !collapsed);
    setCollapsed(!collapsed);
  };

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    const sidebarStatus = StorageServices.get("SB-Sidebar-Collapse")
      ? JSON.parse(StorageServices.get("SB-Sidebar-Collapse"))
      : true;

    setCollapsed(sidebarStatus);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
        isDrawerOpen,
        toggleDrawer,
        closeDrawer,
        setIsDrawerOpen,
        isModalOpen,
        toggleModal,
        closeModal,
        isUpdate,
        setIsUpdate,
        isCreateOpen,
        setIsCreateOpen,
        create,
        setCreate,
        collapsed,
        setCollapsed,
        toggleCollapse,
        showSideBar,
        setShowSideBar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
