import React, { createContext, useReducer } from "react";
import StorageServices from "../services/StorageServices";

export const AdminContext = createContext();

const initialState = {
  adminInfo: StorageServices.get("adminInfo")
    ? JSON.parse(StorageServices.get("adminInfo"))
    : null,
  activeStore: StorageServices.get("SB-Active-Store")
    ? StorageServices.get("SB-Active-Store")
    : null,
  activeStoreId: StorageServices.get("SB-Active-Store-Id")
    ? StorageServices.get("SB-Active-Store-Id")
    : null,
};

function reducer(state, action) {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, adminInfo: action.payload };

    case "VERIFY_EMAIL":
      return {
        ...state,
        adminInfo: { ...state.adminInfo, email_verified: true },
      };
    case "USER_LOGOUT":
      StorageServices.clearAll();
      return { ...state, adminInfo: null };

    case "ACTIVE_STORE":
      return { ...state, activeStore: action.payload };

    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
