import { ClientJS } from "clientjs";
import requests from "./httpService";
const platform = require("platform");

const client = new ClientJS();

const AdminServices = {
  registerAdmin(body, IP) {
    const data = {
      app_id: "storebounty",
      ...body,
      reg_channel: "web",
      platform: "web",
      firebase_device_id: null,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fingerprint: {
        id: client.getFingerprint(),
        model: platform.description || null,
        os_version: platform.version || null,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        manufacturer: platform.manufacturer || null,
        is_physical_device: true,
        screen_resolution: client.getCurrentResolution(),
        firebase_device_id: null,
        ip: IP,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    return requests.post(
      "/accounts/api/v1/auth/users/register",
      JSON.stringify(data)
    );
  },

  loginAdmin(body, IP) {
    const payload = {
      app_id: "storebounty",
      email: body.email,
      platform: "web",
      password: body.password,
      fingerprint: {
        id: client.getFingerprint(),
        model: platform.description || null,
        os_version: platform.version || null,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        manufacturer: platform.manufacturer || null,
        is_physical_device: true,
        screen_resolution: client.getCurrentResolution(),
        firebase_device_id: null,
        ip: IP,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    return requests.post(`/accounts/api/v1/auth/users/login`, payload);
  },

  googleSigninInit() {
    const payload = {
      app_id: "storebounty",
    };
    return requests.post(
      `/accounts/api/v1/auth/users/google/signin/init`,
      JSON.stringify(payload)
    );
  },

  googleSignin(payload) {
    return requests.post(
      `/accounts/api/v1/auth/users/google/signin`,
      JSON.stringify(payload)
    );
  },

  verifyEmail(body) {
    return requests.post("/accounts/api/v1/users/email/verify", body);
  },

  sendRestOTP(body) {
    return requests.post("/accounts/api/v1/auth/password/otp/send", body);
  },

  sendEmailOTP(body) {
    return requests.post("/accounts/api/v1/users/email/otp/send", body);
  },

  forgetPassword(body) {
    return requests.post("/accounts/api/v1/auth/password/reset", body);
  },

  resetPassword(body) {
    return requests.put("/admin/reset-password", body);
  },

  refreshToken() {
    return requests.post("/accounts/api/v1/users/token/refresh");
  },

  // IOS login
  registerIos(body, IP) {
    const data = {
      app_id: "storebounty",
      ...body,
      reg_channel: "web",
      platform: "web",
      firebase_device_id: null,
      source: "ios",
      include_otp: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fingerprint: {
        id: client.getFingerprint(),
        model: platform.description || null,
        os_version: platform.version || null,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        manufacturer: platform.manufacturer || null,
        is_physical_device: true,
        screen_resolution: client.getCurrentResolution(),
        firebase_device_id: null,
        ip: IP,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    return requests.post(
      "/accounts/api/v1/auth/users/register",
      JSON.stringify(data)
    );
  },

  loginIos(body, IP) {
    const payload = {
      app_id: "storebounty",
      email: body.email,
      platform: "mobile",
      source: "ios",
      password: body.password,
      include_otp: true,

      fingerprint: {
        id: client.getFingerprint(),
        model: platform.description || null,
        os_version: platform.version || null,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight,
        manufacturer: platform.manufacturer || null,
        is_physical_device: true,
        screen_resolution: client.getCurrentResolution(),
        firebase_device_id: null,
        ip: IP,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
    return requests.post(`/accounts/api/v1/auth/users/login`, payload);
  },

  sendEmailOTPIos(body) {
    return requests.post("/accounts/api/v1/auth/users/otp/login", body);
  },
};

export default AdminServices;
