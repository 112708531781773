import React from "react";

const ThemeSuspense = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      {/* Assuming your loading GIF is named "loading.gif" and located in the public folder */}
      <img src={`${process.env.PUBLIC_URL}/loading1.gif`} alt="Loading..." />
    </div>
  );
};

export default ThemeSuspense;
