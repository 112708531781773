import React, { useMemo } from "react";

import { Button } from "@windmill/react-ui";
import Storebounty_logo from "../../assets/img/Storebounty_icon.png";
import { modulesImages } from "../../constants";
import useAsync from "../../hooks/useAsync";
import ModuleServices from "../../services/ModuleServices";
import ModuleCard from "../modules/ModuleCard";
import Loading from "../preloader/Loading";
import ProgressBar from "../progressbar/ProgressBar";
import BackButton from "./BackButton";
import OnBoardingContainer from "./onBoardingContainer";

// TODO Sync with create store in admin
const activeModules = {
  sellOnline: ["module_online", "module_marketing", "module_messaging"],
  sellInPerson: ["module_pos", "module_expenses"],
  sellInBoth: ["module_online", "module_marketing"],
};

export default function StoreMode({
  onSubmit,
  setStoreData,
  isLoading,
  setStep,
  storeData,
}) {
  const { data, loading } = useAsync(ModuleServices.getModules);

  const moduleState = data?.data;
  const moduleSelectedForYou = useMemo(() => {
    let selectedModules = [];
    if (storeData.pos_support) {
      selectedModules = activeModules.sellInPerson;
    }
    if (storeData.web_support) {
      selectedModules = activeModules.sellOnline;
    }
    if (storeData.pos_support && storeData.store_module === "restaurant") {
      selectedModules.push("module_food_menu");
    }
    const moduleObj = {};
    selectedModules.forEach((item) => {
      moduleObj[item] = "active";
    });
    setStoreData((prev) => ({ ...prev, ...moduleObj }));
    return selectedModules;
  }, [storeData.pos_support, storeData.web_support, storeData.store_module]);

  return (
    <div>
      <div className="min-h-screen bg-green-50 py-8 px-3">
        <div className="container mx-auto">
          <div className="w-full ">
            <OnBoardingContainer>
              <div className=" p-6 lg:p-8 lg:px-10  ">
                <img
                  src={Storebounty_logo}
                  alt=""
                  className="w-40 h-auto mb-6"
                />
                <ProgressBar level={"70%"} />

                <h1 className="font-medium text-lg  mt-6">
                  Suitable modules for your account type
                </h1>
                <p className="text-xs  mt-1 text-gray-500  w-8/12">
                  The modules are selected based on your account type, you can
                  add or remove from these modules
                </p>

                <div className="mt-8">
                  <h2 className="py-4 text-sm font-medium">
                    Available modules
                  </h2>
                  <form className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {moduleState &&
                      moduleSelectedForYou?.map((item) => {
                        const moduleInfo = moduleState[item];
                        const isActive = storeData[item];
                        return (
                          <ModuleCard
                            key={item}
                            saving={false}
                            title={moduleInfo?.title}
                            value={isActive}
                            handleChange={(name, value) => {
                              setStoreData((prev) => ({
                                ...prev,
                                [name]: value ? "active" : "disabled",
                              }));
                            }}
                            name={item}
                            image={modulesImages[item]}
                            description={moduleInfo?.description}
                          />
                        );
                      })}
                  </form>

                  <Loading loading={loading} />

                  <h2 className="py-4 text-sm font-medium">
                    Available modules
                  </h2>
                  <form className="grid lg:grid-cols-2 xl:grid-cols-3 gap-4">
                    {moduleState &&
                      Object.entries(moduleState)
                        .filter(([key]) => !moduleSelectedForYou.includes(key))
                        .map(([key, values]) => (
                          <ModuleCard
                            key={key}
                            saving={false}
                            title={values.title}
                            value={storeData[key]}
                            handleChange={(name, value) => {
                              console.log({ name, value });
                              console.log("storeData[name]", storeData[name]);
                              setStoreData((prev) => ({
                                ...prev,
                                [name]: value ? "active" : "disabled",
                              }));
                            }}
                            name={key}
                            image={values.image}
                            description={moduleState[key]?.description}
                          />
                        ))}
                  </form>
                </div>
                <div className="lg:col-span-2 mt-8 px-4 w-full mx-auto flex justify-between">
                  <BackButton setStep={setStep} />
                  <Button
                    disabled={!storeData.pos_support && !storeData.web_support}
                    onClick={() => setStep((prev) => prev + 1)}
                    type="button"
                    className="lg:px-12 px-6 my-6"
                    style={{ borderRadius: "4px" }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </OnBoardingContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
